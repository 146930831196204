import React from 'react';
import contact from '../../assets/images/contact/contact-us.png';
import Form from './Form';

const ContactForm = () => {
  return (
    <section className='contact-area pt-100 pb-100'>
      <div className='container'>
        <div className='section-title'>
          <span className='sub-title'>Get in Touch</span>
          <h2>Ready to Get Started?</h2>
          <p>Your email address will not be published.</p>
        </div>

        <div className='row'>
          <div className='col-lg-6 col-md-12'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='contact-info-box'>
                  <div className='back-icon'>
                    <i className='bx bx-map'></i>
                  </div>
                  <div className='icon'>
                    <i className='bx bx-map'></i>
                  </div>
                  <h3>Ekko </h3>
                  <p>Palo Alto, Ca</p>
                </div>
              </div>

              <div className='col-md-12'>
                <div className='contact-info-box'>
                  <div className='back-icon'>
                    <i className='bx bx-phone-call'></i>
                  </div>
                  <div className='icon'>
                    <i className='bx bx-phone-call'></i>
                  </div>
                  <h3>Contact</h3>
                  <p>
                    Mobile: <a href='tel:+016503088212'>(650) 308-8212</a>
                  </p>
                  <p>
                    E-mail: <a href='mailto:ekko-info@ekkoconsulting.com'>ekko-info@ekkoconsulting.com</a>
                  </p>
                </div>
              </div>
              <div className='contact-image'>
                <img src={contact} style={{ maxWidth: '150px' }} alt='contact' />
              </div>
            </div>
          </div>

          <div className='col-lg-6 col-md-12'>
            <div className='contact-form'>
              <form id='contactForm'>
                <div className='row'>
                  <div className='col-lg-12 col-md-6'>
                    <Form />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
